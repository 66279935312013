<template>
  <el-dialog
    :title="type === 1 ? '添加我的应用' : '编辑我的应用'"
    :visible.sync="isAppDialogShow"
    width="700px"
    :before-close="handleClose"
    top="10px"
  >
    <img
      class="close"
      src="@/assets/images/homePage/close.png"
      @click="handleClose"
      alt="图片"
    />

    <div class="box-line">
      <div class="box-name">
        <img class="star" src="@/assets/images/homePage/star.png" alt="图片" />
        <span>应用名称：</span>
      </div>
      <input v-model="name" maxlength="1000" placeholder="例如:我的应用" />
    </div>
    <div class="box-line">
      <div class="box-name">
        <img
          class="star"
          v-show="cateId === 2"
          src="@/assets/images/homePage/star.png"
          alt="图片"
        />
        <span>应用地址：</span>
      </div>
      <input
        v-model="uri"
        maxlength="1000"
        placeholder="例如：http：//example.com"
      />
    </div>
    <div class="box-line">
      <div class="box-name">
        <img class="star" src="@/assets/images/homePage/star.png" alt="图片" />
        <span>平台类别：</span>
      </div>
      <div class="category">
        <div
          v-for="item in PlatformTypes"
          :class="[
            'category-item',
            { 'category-item-active': cateId === item.id },
          ]"
          :key="item.id"
          @click="cateId = item.id"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="box-line">
      <div class="box-name">
        <img class="star" src="@/assets/images/homePage/star.png" alt="图片" />
        <span>行业类别：</span>
      </div>
      <el-select v-model="industryId" placeholder="请选择">
        <el-option
          v-for="item in IndustryTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="box-line">
      <div class="box-search">
        <div class="box-name">
          <img
            class="star"
            src="@/assets/images/homePage/star.png"
            alt="图片"
          />
          <span>执行国家(地区)：</span>
        </div>
        <div class="search">
          <el-select v-model="countrysItem" filterable placeholder="请选择">
            <el-option
              v-for="item in countrysOptions"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
          <el-button class="search-add" @click="countryAdd">添加</el-button>
        </div>
      </div>
      <div class="countrys">
        <div class="country-box" v-for="(item, index) in countrys" :key="index">
          {{ item.name }}
          <img
            class="country-close"
            src="@/assets/images/homePage/country-close.png"
            alt="图片"
            @click="deletedCountry(item.id)"
          />
        </div>
      </div>
    </div>
    <div class="box-line">
      <div class="box-name">
        <img class="star" src="@/assets/images/homePage/star.png" alt="图片" />
        <span>应用介绍：</span>
      </div>

      <el-input
        type="textarea"
        :rows="4"
        placeholder="建议填写详细内容，可获取更准确的数据"
        v-model="summary"
        maxlength="1000"
      >
      </el-input>
    </div>

    <div class="buttons">
      <el-button class="cancel" @click="handleClose" round>取消</el-button>
      <el-button class="add" @click="setCreateApp" round
        >{{ type === 1 ? "添加" : "编辑" }}应用</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { getCountryAll, setCreateApp, setEditApp } from "@/api/app";
import { IndustryTypes, PlatformTypes } from "@/utils/data";
export default {
  props: {
    isAppDialogShow: {
      type: Boolean,
      default: false,
    },
    editingData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      countrysCopy: [],
      countrys: [],
      countrysOptions: [],
      IndustryTypes,
      PlatformTypes,
      name: "",
      uri: "",
      industryId: null,
      cateId: null,
      summary: null,
      type: 1,
      id: null,
      countrysItem: null,
    };
  },
  watch: {
    editingData(newValue) {
      if (!newValue) {
        this.type = 1;
        this.id = null;
        this.name = "";
        this.uri = "";
        this.industryId = null;
        this.cateId = null;
        this.summary = null;
        this.countrys = [];
      } else {
        this.type = 2;
        this.id = newValue.id;
        this.name = newValue.name;
        this.uri = newValue.uri;
        this.industryId = newValue.industryId;
        this.cateId = newValue.cateId;
        this.summary = newValue.summary;
        this.countrys = [];
        this.countrysCopy.forEach((item) => {
          if (newValue.countries.indexOf(item.id) !== -1) {
            this.countrys.push(item);
          }
        });
      }
    },
  },

  created() {
    this.getCountryAll();
    console.log("1", this.editingData);
  },
  methods: {
    handleClose() {
      this.$emit("handleAppDialogClose", false);
    },
    //获取全部国家
    getCountryAll() {
      getCountryAll().then((res) => {
        if (res.status === 200) {
          this.countrysOptions = res.data;
          this.countrysCopy = JSON.parse(JSON.stringify(res.data));
          console.log("全部国家触发", this.countrysCopy);
        }
      });
    },
    //删除国家
    deletedCountry(id) {
      this.countrys = this.countrys.filter((item) => {
        return item.id !== id;
      });
    },
    //添加国家
    countryAdd() {
      if (!this.countrysItem && this.countrysItem !== 0) {
        this.$message({
          message: "请选择需要添加的国家地区",
          type: "warning",
        });
        return;
      }
      const index = this.countrys.findIndex((item) => {
        return item.id === this.countrysItem;
      });
      if (index !== -1) {
        this.$message({
          message: "该国家地区已添加",
          type: "warning",
        });
        return;
      }

      // if (this.countrys.length === 5) {
      //   this.$message({
      //     message: "最多只能选择5个国家(地区)",
      //     type: "warning",
      //   });
      //   return;
      // }
      const item = this.countrysCopy.find((item) => {
        return item.id === this.countrysItem;
      });
      this.countrys.push(item);
    },
    //创建应用和编辑应用
    setCreateApp() {
      const countries = [];
      this.countrys.map((item) => {
        countries.push(item.id);
      });
      if (!this.name) {
        this.$message({
          message: "应用名称不能为空",
          type: "warning",
        });
        return;
      }
      if (!this.uri && this.cateId === 2) {
        this.$message({
          message: "应用地址不能为空",
          type: "warning",
        });
        return;
      }
      if (!this.cateId) {
        this.$message({
          message: "平台类别不能为空",
          type: "warning",
        });
        return;
      }
      if (!this.industryId) {
        this.$message({
          message: "行业类别不能为空",
          type: "warning",
        });
        return;
      }
      if (countries.length === 0) {
        this.$message({
          message: "执行国家(地区)至少选择一个",
          type: "warning",
        });
        return;
      }
      if (!this.summary) {
        this.$message({
          message: "应用介绍不能为空",
          type: "warning",
        });
        return;
      }
      const params = {
        name: this.name,
        uri: this.uri,
        industryId: this.industryId,
        cateId: this.cateId,
        summary: this.summary,
        countries: countries,
      };
      if (this.type === 2) {
        params.id = this.id;
        setEditApp(params).then((res) => {
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: "编辑成功",
              type: "success",
            });
            this.$emit("handleAppDialogClose", true);
          }
        });
      } else {
        setCreateApp(params).then((res) => {
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: "新增成功",
              type: "success",
            });
            this.$emit("handleAppDialogClose", true);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__header {
  padding: 30px 30px 10px 30px;
}
/deep/.el-dialog__body {
  padding: 25px 30px 30px 25px;
}
/deep/ .el-dialog__title {
  font-size: 26px;
  font-weight: bold;
  color: #06052b;
}
/deep/.el-dialog__headerbtn {
  display: none;
}
.close {
  position: absolute;
  top: 36px;
  right: 30px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.box-line {
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  position: relative;
  .box-name {
    display: flex;
    align-items: center;
    font-size: #06052b;
    .star {
      width: 12px;
      height: 12px;
    }
  }
  input {
    margin-top: 11px;
    padding-left: 14px;
    background-color: #fafafa;
    height: 36px;
    line-height: 36px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
  }
  .category {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .category-item {
      width: 110px;
      height: 36px;
      line-height: 36px;
      background: #fafafa;
      border-radius: 4px;
      text-align: center;
      color: #06052b;
    }
    .category-item-active {
      color: #fff;
      background-color: #4578ff;
    }
  }
  .el-select {
    background-color: #fafafa;
    margin-top: 16px;
  }
  .box-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
      display: flex;
      align-items: center;
      width: 220px;
      .el-select {
        width: 150px;
        margin: 0;
        /deep/ .el-input__inner {
          height: 36px;
        }
      }
      .search-add {
        width: 50px;
        height: 36px;
        padding: 0;
        background: #4578ff;
        color: #fff;
        margin-left: 10px;
      }
    }
  }
  .countrys {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .country-box {
      position: relative;
      height: 32px;
      padding: 0 14px;
      line-height: 32px;
      text-align: center;
      background: #4578ff;
      border-radius: 4px;
      color: #fff;
      margin-right: 24px;
      margin-bottom: 10px;
      cursor: pointer;
      .country-close {
        position: absolute;
        width: 10px;
        height: 10px;
        right: -5px;
        top: -5px;
      }
    }
  }
  .el-textarea {
    margin-top: 16px;
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 54px;
  .cancel {
    border-color: #cccccc;
    background-color: #fff;
    color: #606266;
    height: 36px;
  }
  .add {
    width: 136px;
    height: 36px;
    padding: 0;
    background: #4578ff;
    color: #fff;
  }
}
</style>