<template>
  <div class="app-lication-main">
    <div v-if="$store.state.application.isApplication" class="content">
      <div class="cards">
        <div class="left">
          <div
            class="circle-box"
            v-for="item in probabilityList"
            :key="item.name"
          >
            <el-progress
              type="circle"
              :percentage="item.number"
              :width="84"
            ></el-progress>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
        <div class="centre">
          <div class="app-types" v-for="item in appTypes" :key="item.img">
            <img :src="item.img" alt="图片" />
            <div class="name">{{ item.name }}</div>
            <div class="number">{{ item.number }}</div>
          </div>
        </div>
        <div class="right">
          <div class="top">
            <div class="img-box">
              <div class="title" @click="isCodeShow = !isCodeShow">
                专属客服
              </div>
              <img
                src="@/assets/images/appLications/kefu.png"
                @click="isCodeShow = !isCodeShow"
                alt="图片"
              />
              <!-- <div class="code" v-show="isCodeShow">
                <img class="code" src="@/assets/images/appLications/code.jpg" alt="图片" />
                <div class="code-title">扫码关注客服号</div>
              </div> -->
              <img
                class="code-kf"
                v-show="isCodeShow"
                src="@/assets/images/appLications/code.jpg"
                alt="图片"
              />
            </div>
            <div class="img-box" @click="skip('/resource')">
              <div class="title">技术支持</div>
              <img src="@/assets/images/appLications/jishu.png" alt="图片" />
            </div>
            <div class="img-box">
              <div class="title" @click="skip('/resource')">帮助</div>
              <img src="@/assets/images/appLications/wenhao.png" alt="图片" />
            </div>
          </div>
          <div class="update-log">
            <img src="@/assets/images/appLications/star.png" alt="图片" />
            <div class="title">更新日志</div>
            <div class="blog">
              <div :class="active === 4 ? 'span-box2' : 'span-box'">
                <span v-for="(item, index) in blog" :key="index">
                  {{ item.btitle }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="app-list-screen">
        <div class="title">应用列表</div>
        <div class="screen">
          <el-select
            v-model="value"
            @change="getApps"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="search">
            <input
              maxlength="1000"
              placeholder="请输入应用名称"
              v-model="key"
            />
            <el-button type="primary" @click="getApps"
              >搜索 <i class="el-icon-search el-icon--right"
            /></el-button>
          </div>
        </div>
      </div>

      <div v-if="appLoading === true" class="app-card">
        <el-skeleton :rows="4" animated style="width: 100%; margin: 25px" />
      </div>

      <div v-if="!appLoading && appList?.length === 0" class="add-app">
        <el-empty :image-size="80" style="padding: 0"></el-empty>
      </div>

      <div
        v-if="appLoading === false"
        v-for="(item, index) in appList"
        :key="index"
        class="app-card"
      >
        <div class="left">
          <div class="top-row">
            <div class="name">{{ item.name }}</div>
            <div class="application-type">{{ item.cateId | getNewCateId }}</div>
            <div class="auditStatus">
              <span
                :style="{
                  'background-color':
                    item.status === 0
                      ? '#5F8BFF'
                      : item.status === 1
                      ? '#5AE3AB'
                      : '#EA8069',
                }"
              ></span>
              <div
                :style="{
                  color:
                    item.status === 0
                      ? '#5F8BFF'
                      : item.status === 1
                      ? '#5AE3AB'
                      : '#EA8069',
                }"
              >
                {{
                  item.status === 0
                    ? "审核中"
                    : item.status === 1
                    ? "已通过"
                    : "已拒绝"
                }}
              </div>
            </div>
            <img
              class="left-delete"
              src="@/assets/images/appLications/application-delete.png"
              alt="图片"
              @click="deleteApp(item)"
            />
          </div>
          <div class="code">
            <div class="name">app Key:</div>
            <div class="code-number">{{ item.appKey }}</div>
            <img
              class="copy"
              src="@/assets/images/appLications/copy.png"
              alt="图片"
              @click="copyContent(item.appKey)"
            />
          </div>
          <div class="key">
            <div class="name">security Key:</div>
            <div class="key-number">{{ item.securityKey | getKey }}</div>
            <img
              class="copy"
              src="@/assets/images/appLications/copy.png"
              alt="图片"
              @click="copyContent(item.securityKey)"
            />
          </div>

          <div class="countrys">
            <div class="name">执行国家(地区):</div>
            <div class="img-box">
              <el-tooltip
                class="item"
                effect="dark"
                v-for="cItem in item.countries"
                :key="cItem"
                v-bind:content="cItem | getCountries(countrysOptions)"
                placement="top-start"
              >
                <img
                  class="countrys-img"
                  :src="require(`@/assets/images/nationalFlag/${cItem}.png`)"
                  alt="图片"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="operate-box" @click="isAppDialogShow = true">
            <img
              src="@/assets/images/appLications/application-editing.png"
              alt="图片"
            />
            <div class="name" @click="applicationEditing(item)">应用编辑</div>
          </div>
          <div class="operate-box" @click="dataDownload(item)">
            <img
              src="@/assets/images/appLications/download.png"
              alt="图片"
            />
            <div class="name">QC数据</div>
          </div>
          <div
            class="operate-box"
            @click="skip(`/application/setting?id=${item.id}`)"
          >
            <img
              src="@/assets/images/appLications/application_setup.png"
              alt="图片"
            />
            <div class="name">应用设置</div>
          </div>
          <div
            class="operate-box"
            @click="skip(`/application/questionnaireList?id=${item.id}`)"
          >
            <img
              src="@/assets/images/appLications/questionnaire-list.png"
              alt="图片"
            />
            <div class="name">问卷列表</div>
          </div>
        </div>
      </div>

      <div class="add-app">
        <el-button round @click="addApp">
          <img src="@/assets/images/homePage/add.png" /><span
            >添加应用</span
          ></el-button
        >
      </div>
      <Footer></Footer>
      <el-dialog
        class="data-download"
        title="数据下载"
        :visible.sync="dataDownloadVisible"
        width="600px"
        :before-close="dataDownloadClose"
      >
        <span>请先选中审核日期范围(90天内)，点击生成链接即可下载</span>
        <div class="download-pickers">
          <el-date-picker
            v-model="datePicker"
            type="daterange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeDatePicker"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>

          <div class="date-download" v-if="dateDiffer">
            <span v-if="!isPercentage&&progressMessage===null" @click="getAppsBadData"
              >{{ dateDiffer }}天数据下载</span
            >
            <span v-if="!isPercentage&&progressMessage">{{progressMessage}}</span>
            <el-progress
              v-if="isPercentage"
              :percentage="percentage"
              color="#68EBB5"
            ></el-progress>
          </div>
        </div>
      </el-dialog>
    </div>
    <Application v-else @refresh="refresh"></Application>
    <AppDialog
      :isAppDialogShow="isAppDialogShow"
      :editingData="editingData"
      @handleAppDialogClose="handleAppDialogClose"
    ></AppDialog>
  </div>
</template>
<script>
import Application from "@/components/Application";
import AppDialog from "@/components/Application/appDialog.vue";
import Footer from "@/components/Footer/homePageFooter";
import {
  getAppCateStats,
  getApps,
  setDeleteApp,
  getCountryAll,
  getBlog,
  getAppsBadData,
} from "@/api/app";
import { applicationAppTypes, PlatformTypes } from "@/utils/data";
import { getDiffDay } from "@/utils/date";
import request from "@/utils/request";
export default {
  components: { Application, Footer, AppDialog },
  filters: {
    getNewCateId(data) {
      let value = null;
      PlatformTypes.forEach((item) => {
        if (item.id === data) {
          value = item.name;
        }
      });
      return value;
    },
    //key显示部分
    getKey(value) {
      let start = value.substring(0, 5);
      let end = value.substring(value.length - 5, value.length);
      value = start + "***" + end;
      return value;
    },

    getCountries(value, countrysOptions) {
      const data = countrysOptions.find((item) => {
        return item.id === value;
      });
      return data.name;
    },
  },
  data() {
    return {
      appTypes: applicationAppTypes,
      isCodeShow: false,
      value: null,
      options: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 0,
          label: "审核中",
        },
        {
          value: 1,
          label: "已通过",
        },
        {
          value: 2,
          label: "已拒绝",
        },
      ],
      appList: null,
      isAppDialogShow: false,
      editingData: null,
      key: null,
      probabilityList: [
        {
          name: "通过率",
          number: 0,
        },
        {
          name: "废除率",
          number: 0,
        },
      ],
      countrysOptions: [],
      blog: null,
      timer: null,
      active: 0,
      appLoading: false,
      dataDownloadVisible: false,
      dataDownloadId: null,
      datePicker: null,
      dateDiffer: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      percentage: 0,
      isPercentage: false,
      dataDownloadName: null,
      progressMessage: null
    };
  },
  created() {
    this.getAppCateStats();
    this.getCountryAll();
    if (this.userInfo) {
      this.probabilityList[0].number = parseInt(this.userInfo.conversion * 100);
      this.probabilityList[1].number = parseInt(this.userInfo.reversals * 100);
    }
    if (this.$store.state.application.isApplication) {
      this.getBlog();
    }
  },
  watch: {
    userInfo(newValue) {
      if (newValue) {
        this.probabilityList[0].number = parseInt(newValue.conversion * 100);
        this.probabilityList[1].number = parseInt(newValue.reversals * 100);
      }
    },
    isApplication2(newValue, oldVAluse) {
      if (!oldVAluse) {
        this.getBlog();
      }
      if (!newValue) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    isApplication2() {
      return this.$store.state.application.isApplication;
    },
  },
  methods: {
    //跳转
    skip(path) {
      this.$router.push(path);
    },
    //复制
    copyContent(data) {
      this.$copyText(data).then(
        () => {
          this.$message.success("复制成功");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
    //获取全部国家
    getCountryAll() {
      getCountryAll().then((res) => {
        if (res.status === 200) {
          this.countrysOptions = res.data;
          console.log("this.countrysOptions", this.countrysOptions);
          this.getApps();
        }
      });
    },
    //获取更新日志
    getBlog() {
      const params = {
        cateId: 1,
      };
      getBlog(params).then((res) => {
        if (res.status === 200) {
          console.log("更新日志", res.data.data);
          if (res.data.data.length !== 0) {
            this.blog = res.data.data;
            this.timer = setInterval(() => {
              let topRemove = 0;
              switch (this.active) {
                case 0:
                  topRemove = -24;
                  break;
                case 1:
                  topRemove = -48;
                  break;
                case 2:
                  topRemove = 0;
                  break;
              }
              this.active = this.active + 1;
              console.log("topRemove", topRemove);
              this.$nextTick(() => {
                document.getElementsByClassName(
                  "span-box"
                )[0].style.transform = `translateY(${topRemove}px)`;
                setTimeout(() => {
                  if (this.active === 3) {
                    this.active = 4;
                    this.$nextTick(() => {
                      document.getElementsByClassName(
                        "span-box2"
                      )[0].style.transform = `translateY(0)`;
                      setTimeout(() => {
                        this.active = 0;
                      }, 300);
                    });
                  }
                }, 300);
              });
            }, 3000);
          }
        }
      });
    },
    //关闭弹窗
    handleAppDialogClose() {
      this.isAppDialogShow = false;
      this.key = null;
      this.value = null;
      this.getApps();
      this.getAppCateStats();
    },
    // 刷新
    refresh() {
      this.getApps();
      this.getAppCateStats();
    },
    //应用分类统计
    getAppCateStats() {
      getAppCateStats().then((res) => {
        if (res.status === 200) {
          this.appTypes.forEach((item, index, array) => {
            if (item.name === "公众号") {
              array[index].number = res.data.officialAccount;
            }
            if (item.name === "APP") {
              array[index].number = res.data.app;
            }
            if (item.name === "WEB") {
              array[index].number = res.data.web;
            }
            if (item.name === "小程序") {
              array[index].number = res.data.miniProgram;
            }
            if (item.name === "群组") {
              array[index].number = res.data.socialGroup;
            }
          });
        }
      });
    },
    //获取应用列表
    getApps() {
      this.appLoading = true;
      const params = {
        page: 1,
        size: 100,
        key: this.key,
        status: this.value,
      };
      getApps(params).then((res) => {
        if (res.status === 200) {
          this.appList = res.data.data;
          console.log("this.appList", this.appList);
        }
        this.appLoading = false;
      });
    },
    //判断是否还存在应用
    isApplication() {
      const params = {
        page: 1,
        size: 16,
      };
      getApps(params).then((res) => {
        if (res.status === 200) {
          if (res.data.dataCount > 0) {
            this.$store.commit("application/setIsApplication", true);
            localStorage.setItem("application", true);
          } else {
            this.$store.commit("application/setIsApplication", false);
            localStorage.setItem("application", false);
          }
        }
      });
    },
    //添加应用
    addApp() {
      this.isAppDialogShow = true;
      this.editingData = null;
    },
    //应用编辑
    applicationEditing(data) {
      this.isAppDialogShow = true;
      this.editingData = data;
      console.log("data", data);
    },
    //应用删除
    deleteApp(data) {
      const params = {
        id: data.id,
      };
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning", 
      })
        .then(() => {
          setDeleteApp(params).then((res) => {
            if (res.status === 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getApps();
              this.isApplication();
            }
          });
        })
        .catch(() => {});
    },
    //数据下载
    dataDownload(data) {
      this.dataDownloadVisible = true;
      console.log("data", data);
      this.dataDownloadId = data.id;
      this.dataDownloadName = data.name;
    },
    dataDownloadClose() {
      this.dataDownloadVisible = false;
      this.datePicker = null;
      this.dateDiffer = null;
      this.dataDownloadId = null;
      this.dataDownloadName = null;
      this.isPercentage = false;
      this.progressMessage=null;
    },
    // 日期发生改变
    changeDatePicker() {
      console.log(this.datePicker);
      if (this.datePicker) {
        const dateDiffer = getDiffDay(this.datePicker[1], this.datePicker[0]);
        if (dateDiffer > 90) {
          this.dateDiffer = null;
          this.isPercentage = false;
          this.$message({
            message: "日期范围最多为90天",
            type: "warning",
          });
        } else {
          this.dateDiffer = dateDiffer;
        }
      } else {
        this.dateDiffer = null;
        this.isPercentage = false;
      }
    },
    //数据下载
    getAppsBadData() {
      this.progressMessage = "数据生成中..";
      const params = {
        id: this.dataDownloadId,
        startDate: this.datePicker[0],
        endDate: this.datePicker[1],
      };
      getAppsBadData(params).then((res) => {
        console.log(1223, res);
        let that = this;

        if (res.status === 200) {
          that.progressMessage = "开始下载..";
          setTimeout(() => {
            that.isPercentage = true;
          }, 500);          
          request({
            method: "get",
            responseType: "blob",
            url: process.env.VUE_APP_SERVER + `api/v1/File/Down`,
            params: {
              filename: res.data,
            },
            onDownloadProgress(progressEvent) {
              //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
              //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
              that.percentage = parseInt(
                (progressEvent.loaded / progressEvent.total) * 100
              );
            },
          }).then((res) => {
            that.progressMessage = null
            console.log("数据下载完成", res);

            if (!res) {
              return;
            }
            // 无法导出时，报错的问题的处理
            if (res.type == "application/json") {
              const reader = new FileReader();
              reader.readAsText(res, "utf-8");
              reader.onload = function () {
                const msg = JSON.parse(reader.result);
                console.log("无法处理", msg);
              };
              return;
            }
            // 以下载xls文件为例
            let fileName = this.dataDownloadName;
            let suffix = "xls";
            let url = window.URL.createObjectURL(new Blob([res]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", fileName + "." + suffix);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          });
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style lang="scss" scoped>
.app-lication-main {
  width: 100%;
  height: 100%;
  .content {
    padding: 20px;
    width: calc(100% - 40px);
    .cards {
      display: flex;
      justify-content: space-between;
      height: 153px;
      .left {
        width: 380px;
        height: 153px;
        background: #ffffff;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        .circle-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 60px;
          .name {
            font-size: 14px;
            margin-top: 12px;
          }
        }
        .circle-box:last-child {
          margin-right: 0;
        }
      }
      .centre {
        width: 378px;
        height: 153px;
        background: #ffffff;
        border-radius: 4px;
        display: flex;
        justify-content: space-around;
        .app-types {
          width: 40px;
          img {
            width: 40px;
            margin-top: 24px;
          }
          .name {
            font-size: 12px;
            text-align: center;
            margin-top: 8px;
            margin-bottom: 10px;
          }
          .number {
            
            font-size: 29px;
            text-align: center;
          }
        }
      }
      .right {
        width: 330px;
        height: 153px;
        padding: 0 24px 0 28px;
        background: #ffffff;
        border-radius: 4px;
        .top {
          display: flex;
          justify-content: space-between;
          margin-top: 34px;
          .img-box {
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
            }
            .title {
              font-size: 14px;
              margin-right: 7px;
            }
            .code {
              position: absolute;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 170px;
              height: 185px;
              background-color: #fff;
              top: 20px;
              z-index: 99;
              img {
                width: 126px;
                height: 126px;
                margin-top: 15px;
              }
              .code-title {
                color: #4578ff;
                font-size: 14px;
                margin-top: 15px;
              }
            }
            .code-kf {
              position: absolute;
              width: 170px;
              height: 302px;
              top: 20px;
              background-color: #fff;
              z-index: 999999;
            }
          }
        }
        .update-log {
          display: flex;
          margin-top: 48px;
          img {
            width: 12px;
            height: 12px;
          }
          .title {
            margin-right: 16px;
            width: 100px;
          }
          .blog {
            width: 200px;
            overflow: hidden;
            height: 24px;
            .span-box {
              transform: translateY(0);
              transition: transform 0.3s ease;
              span {
                display: block;
                overflow: hidden; //超出的文本隐藏
                text-overflow: ellipsis; //溢出用省略号显示
                white-space: nowrap; // 默认不换行；
              }
            }
          }
        }
      }
    }
  }
}

.app-list-screen {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
  .screen {
    display: flex;
    .el-select {
      width: 120px;
      background-color: #fff;
      margin-right: 19px;
      /deep/ .el-input__inner {
        border: none;
      }
    }
    .search {
      position: relative;
      display: flex;
      input {
        width: 184px;
        height: 32px;
        border: 1px solid #dddddd;
        border-radius: 4px;
        padding-left: 10px;
        margin-right: 10px;
        margin-left: 10px;
      }
      .el-button {
        background-color: #4578ff;
        color: #fff;
        height: 32px;
        width: 84px;
        padding: 0;
      }
    }
  }
}
.app-card {
  width: 100%;
  min-height: 191px;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  .left {
    margin-left: 26px;
    width: calc(100% - 300px);
    .top-row {
      display: flex;
      align-items: center;
      margin-top: 25px;
      .name {
        font-weight: bold;
        margin-right: 27px;
      }
      .application-type {
        width: 45px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        background: rgba(69, 120, 255, 0.1);
        border-radius: 8px;
        color: #4578ff;
        font-size: 12px;
        margin-right: 27px;
      }
      .auditStatus {
        display: flex;
        align-items: center;
        font-size: 14px;
        span {
          width: 7px;
          height: 7px;
          border-radius: 7px;
          margin-right: 4px;
        }
      }
    }
    .key {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #06052b;
      margin-top: 11px;
      .name {
        width: 98px;
      }
      .code-number {
        margin: 0 14px 0 2px;
      }
      .vertical {
        height: 12px;
        width: 0;
        border-right: 2px solid #06052b;
        margin-right: 14px;
      }
      .sdk {
        margin-right: 11px;
      }
      .copy {
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-left: 10px;
      }
    }
    .code {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #06052b;
      margin-top: 22px;
      .name {
        width: 98px;
      }
      .key-number {
        margin: 0 14px 0 2px;
      }
      .copy {
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-left: 10px;
      }
    }
    .countrys {
      display: flex;
      align-items: center;
      margin-top: 22px;
      margin-bottom: 10px;
      .name {
        width: 112px;
      }
      .img-box {
        width: calc(100% - 112px);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .countrys-img {
          width: 39px;
          height: 18px;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
    .left-delete {
      width: 16px;
      height: 16px;
      margin-left: 40px;
      cursor: pointer;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .operate-box {
      display: flex;
      align-items: center;
      margin-right: 30px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin-right: 2px;
      }
      .name {
        font-size: 14px;
      }
    }
  }
}
.add-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  .el-button {
    width: 160px;
    height: 40px;
    background: #4578ff;
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      position: relative;
      top: 2.3px;
      width: 16px;
      height: 16px;
      margin: 0 16px 0 0;
    }
  }
}
.data-download {
  /deep/ .el-dialog__title {
    font-weight: bold;
  }
  /deep/ .el-dialog__body {
    padding: 10px 20px 20px 20px;
  }
  .download-pickers {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  .date-download {
    margin-left: 20px;
    color: #4578ff;
    cursor: pointer;
    width: 150px;
    display: flex;
    align-items: center;
    .el-progress {
      width: 100%;
    }
  }
}
</style>